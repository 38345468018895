"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeliveryType = void 0;
var react_1 = require("react");
var _1 = require(".");
var useDeliveryType = function () {
    var cartItems = (0, _1.useCartItems)().cartItems;
    return (0, react_1.useMemo)(function () {
        if (!cartItems.length)
            return "in-store";
        var toDispatch = cartItems.some(function (item) { return item.dispatchQuantity > 0; });
        var toShip = cartItems.some(function (item) { return item.deliverQuantity > 0; });
        if (toDispatch && toShip)
            return "hybrid";
        if (toDispatch)
            return "in-store";
        return "warehouse";
    }, [cartItems]);
};
exports.useDeliveryType = useDeliveryType;
