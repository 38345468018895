"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLineItemDraft = void 0;
var toLineItemDraft = function (item) {
    return {
        sku: item.sku,
        distributionChannel: { key: "online-uk", typeId: "channel" },
        quantity: item.deliverQuantity + item.dispatchQuantity,
        shippingDetails: {
            targets: [
                { addressKey: "store", quantity: item.dispatchQuantity },
                { addressKey: "customer", quantity: item.deliverQuantity },
            ],
        },
    };
};
exports.toLineItemDraft = toLineItemDraft;
