"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterResults = void 0;
var filterResults = function (keyword, results) {
    var _a;
    if (!results)
        return [];
    if (!keyword)
        return results;
    return ((_a = results.filter(function (item) {
        var _a, _b;
        var title = (_b = (_a = item.value.title["en-GB"]) !== null && _a !== void 0 ? _a : item.value.title["en-US"]) !== null && _b !== void 0 ? _b : "";
        return title.toLowerCase().match(keyword.toLowerCase());
    })) !== null && _a !== void 0 ? _a : []);
};
exports.filterResults = filterResults;
