"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNotification = void 0;
var actions_global_1 = require("@commercetools-frontend/actions-global");
var constants_1 = require("@commercetools-frontend/constants");
var useNotification = function () {
    var notification = (0, actions_global_1.useShowNotification)();
    return {
        showErrorNotification: function (text) {
            return notification({ kind: "error", domain: constants_1.DOMAINS.SIDE, text: text, id: 2 });
        },
        showSuccessNotification: function (text) {
            return notification({ kind: "success", domain: constants_1.DOMAINS.SIDE, text: text, id: 1 });
        },
    };
};
exports.useNotification = useNotification;
