"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembershipDropdown = void 0;
var react_1 = __importStar(require("react"));
var ui_kit_1 = require("@commercetools-frontend/ui-kit");
var MembershipDropdown = function (_a) {
    var memberships = _a.memberships, userId = _a.userId, selected = _a.selected, update = _a.update;
    var _b = (0, react_1.useState)(false), changed = _b[0], setChanged = _b[1];
    var _c = (0, react_1.useState)(selected || ""), membershipId = _c[0], setMembershipId = _c[1];
    var changeHandler = function (updatedValue) {
        setChanged(true);
        setMembershipId(updatedValue);
    };
    var updateWrapper = function () {
        update(membershipId, userId);
        setChanged(false);
    };
    return (react_1.default.createElement(ui_kit_1.Spacings.Inline, { justifyContent: "flex-end" },
        react_1.default.createElement(ui_kit_1.SelectInput, { name: "membership-dropdown", placeholder: "Select Type...", options: memberships, value: membershipId, onChange: function (e) { return changeHandler(e.target.value); } }),
        react_1.default.createElement(ui_kit_1.PrimaryButton, { label: "Save", onClick: updateWrapper, isDisabled: !changed })));
};
exports.MembershipDropdown = MembershipDropdown;
exports.default = exports.MembershipDropdown;
