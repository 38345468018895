"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMemberEmailLookUp = void 0;
var react_1 = require("react");
var api_1 = require("../api");
var useCustomerId_1 = require("./useCustomerId");
var useDeliveryAddress_1 = require("./useDeliveryAddress");
var useEmailAddress_1 = require("./useEmailAddress");
var useError_1 = require("./useError");
var useIsMember_1 = require("./useIsMember");
var useMemberEmailLookUp = function () {
    var _a = (0, useEmailAddress_1.useEmailAddress)(), emailAddress = _a.emailAddress, setEmailAddress = _a.setEmailAddress;
    var setIsMember = (0, useIsMember_1.useIsMember)().setIsMember;
    var setDeliveryAddress = (0, useDeliveryAddress_1.useDeliveryAddress)().setDeliveryAddress;
    var setError = (0, useError_1.useError)().setError;
    var setCustomerId = (0, useCustomerId_1.useCustomerId)().setCustomerId;
    var _b = (0, react_1.useState)(emailAddress !== null && emailAddress !== void 0 ? emailAddress : ""), email = _b[0], setEmail = _b[1];
    var _c = (0, react_1.useState)(false), validEnteredEmail = _c[0], setValidEnteredEmail = _c[1];
    var emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    var validEmail = function (e) {
        return emailRegex.test(e.trim());
    };
    (0, react_1.useEffect)(function () {
        if (validEmail(email)) {
            setValidEnteredEmail(true);
            (0, api_1.getMemberDetails)(email)
                .then(function (res) {
                var _a;
                setEmailAddress(email);
                setIsMember(res.isMember);
                setDeliveryAddress((_a = res === null || res === void 0 ? void 0 : res.addresses) === null || _a === void 0 ? void 0 : _a.shipping);
                setCustomerId(res.customerId);
            })
                .catch(function (e) { return setError(e); });
        }
        else {
            setValidEnteredEmail(false);
            setEmailAddress(null);
            setIsMember(false);
        }
    }, [email]);
    return {
        email: email,
        setEmail: setEmail,
        validEnteredEmail: validEnteredEmail,
    };
};
exports.useMemberEmailLookUp = useMemberEmailLookUp;
