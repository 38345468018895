"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeliveryAddress = void 0;
var react_1 = require("react");
var helpers_1 = require("../components/DeliveryAddress/helpers");
var StoreProvider_1 = require("../context/StoreProvider");
var useDeliveryAddress = function () {
    var _a = (0, react_1.useContext)(StoreProvider_1.StoreContext), data = _a.data, setData = _a.setData;
    return (0, react_1.useMemo)(function () {
        var _a;
        return ({
            deliveryAddress: (_a = data.deliveryAddress) !== null && _a !== void 0 ? _a : helpers_1.emptyDeliveryAddress,
            setDeliveryAddress: function (deliveryAddress) {
                return setData(function (prevState) { return (__assign(__assign({}, prevState), { deliveryAddress: deliveryAddress })); });
            },
        });
    }, [data.deliveryAddress]);
};
exports.useDeliveryAddress = useDeliveryAddress;
