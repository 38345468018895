"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLegalCheck = void 0;
var react_1 = require("react");
var StoreProvider_1 = require("../context/StoreProvider");
var useLegalCheck = function () {
    var _a = (0, react_1.useContext)(StoreProvider_1.StoreContext), data = _a.data, setData = _a.setData;
    return (0, react_1.useMemo)(function () { return ({
        legalCheck: data.legalCheck,
        setLegalCheck: function (legalCheck) {
            return setData(function (prevState) { return (__assign(__assign({}, prevState), { legalCheck: legalCheck })); });
        },
    }); }, [data.legalCheck]);
};
exports.useLegalCheck = useLegalCheck;
