"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryAddress = void 0;
var react_1 = __importStar(require("react"));
var hooks_1 = require("../../hooks");
var useDeliveryType_1 = require("../../hooks/useDeliveryType");
var AddressForm_1 = require("./AddressForm");
require("./DeliveryAddress.module.css");
var helpers_1 = require("./helpers");
var DeliveryAddress = function () {
    var deliveryType = (0, useDeliveryType_1.useDeliveryType)();
    (0, helpers_1.useLoqate)();
    var deliveryAddress = (0, hooks_1.useDeliveryAddress)().deliveryAddress;
    var isMember = (0, hooks_1.useIsMember)().isMember;
    var _a = (0, react_1.useState)(false), showAddressForm = _a[0], setShowAddressForm = _a[1];
    if (deliveryType === "in-store")
        return null;
    var firstName = deliveryAddress.firstName, lastName = deliveryAddress.lastName, streetName = deliveryAddress.streetName, city = deliveryAddress.city, country = deliveryAddress.country, postalCode = deliveryAddress.postalCode;
    return (react_1.default.createElement("div", { className: "deliveryAddress" }, isMember ? (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { style: { display: showAddressForm ? "none" : "block" } },
            react_1.default.createElement("p", { className: "subheading" }, "Customers Address"),
            react_1.default.createElement("div", { className: "customerAddress", "data-testid": "customer-address" },
                react_1.default.createElement("p", null,
                    firstName,
                    " ",
                    lastName),
                react_1.default.createElement("p", null, streetName),
                react_1.default.createElement("p", null, city),
                react_1.default.createElement("p", null, country),
                react_1.default.createElement("p", null, postalCode)),
            react_1.default.createElement("p", null,
                react_1.default.createElement("button", { type: "button", className: "editAddress", "data-testid": "edit-address-button", onClick: function () { return setShowAddressForm(true); }, style: { display: showAddressForm ? "none" : "block" } }, "Edit address"))),
        showAddressForm && react_1.default.createElement(AddressForm_1.AddressForm, null))) : (react_1.default.createElement(AddressForm_1.AddressForm, null))));
};
exports.DeliveryAddress = DeliveryAddress;
