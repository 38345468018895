"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useItemHandler = void 0;
var react_1 = require("react");
var useItemHandler = function () {
    var _a = (0, react_1.useState)(null), selectedItem = _a[0], setSelectedItem = _a[1];
    var _b = (0, react_1.useState)(0), inStoreQty = _b[0], setInStoreQty = _b[1];
    var _c = (0, react_1.useState)(0), toShipQty = _c[0], setToShipQty = _c[1];
    return {
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,
        inStoreQty: inStoreQty,
        setInStoreQty: setInStoreQty,
        toShipQty: toShipQty,
        setToShipQty: setToShipQty,
    };
};
exports.useItemHandler = useItemHandler;
