"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyDeliveryAddress = exports.popupAddress = exports.loqateAddressToCTAddress = exports.useLoqate = void 0;
var react_1 = require("react");
var useLoqate = function () {
    (0, react_1.useEffect)(function () {
        var script = document.createElement("script");
        var loqateUrl = "https://services.postcodeanywhere.co.uk/js/address-3.91.js";
        script.src = loqateUrl;
        var link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "https://services.postcodeanywhere.co.uk/css/address-3.91.css";
        document.head.appendChild(link);
        document.body.appendChild(script);
    }, []);
};
exports.useLoqate = useLoqate;
var loqateAddressToCTAddress = function (address) {
    var streetName = [address.lineOne, address.lineTwo].join("|");
    return {
        country: "GB",
        streetName: streetName,
        postalCode: address.postalCode,
        city: address.city,
    };
};
exports.loqateAddressToCTAddress = loqateAddressToCTAddress;
exports.popupAddress = {
    key: "store",
    firstName: "BeautyPie",
    lastName: "Office",
    streetName: "The Foundry | Fulham Palace Rd",
    postalCode: "W6 8AF",
    city: "London",
    country: "GB",
};
exports.emptyDeliveryAddress = {
    firstName: "",
    lastName: "",
    streetName: "",
    postalCode: "",
    city: "",
    country: "GB",
    email: "",
};
