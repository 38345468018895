"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetState = void 0;
var react_1 = require("react");
var StoreProvider_1 = require("../context/StoreProvider");
var useResetState = function () {
    var setData = (0, react_1.useContext)(StoreProvider_1.StoreContext).setData;
    return {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        resetState: function () { return setData(function (_prev) { return StoreProvider_1.initialState; }); },
    };
};
exports.useResetState = useResetState;
