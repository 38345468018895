"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateInventoryEntries = exports.createOrder = exports.editQuantity = exports.deleteItem = exports.addItem = exports.createCart = exports.searchForProduct = exports.getMemberDetails = void 0;
var helpers_1 = require("../components/DeliveryAddress/helpers");
var utils_1 = require("../utils");
var ctapi_1 = require("./ctapi");
var MEMBER_CUSTOMER_GROUP = "pie-member-customer-group";
var DEFAULT_CUSTOMER_GROUP = "pie-default-customer-group";
var getMemberDetails = function (emailAddress) { return __awaiter(void 0, void 0, void 0, function () {
    var customers, customer, customerGroup, isMember, defaultEmpty, addresses, shippingAddress;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, (0, ctapi_1.queryCustomersByEmail)(emailAddress)];
            case 1:
                customers = _c.sent();
                customer = customers.results[0];
                customerGroup = (_a = customer === null || customer === void 0 ? void 0 : customer.customerGroup) === null || _a === void 0 ? void 0 : _a.obj.key;
                isMember = customerGroup === MEMBER_CUSTOMER_GROUP;
                defaultEmpty = {
                    postalCode: "",
                    streetName: "",
                    city: "",
                    country: "GB",
                };
                addresses = {
                    shipping: defaultEmpty,
                };
                if (customer) {
                    shippingAddress = customer.addresses.find(function (address) { return address.id === (customer === null || customer === void 0 ? void 0 : customer.defaultShippingAddressId); });
                    addresses = {
                        shipping: shippingAddress !== null && shippingAddress !== void 0 ? shippingAddress : defaultEmpty,
                    };
                }
                return [2 /*return*/, {
                        isMember: isMember,
                        addresses: addresses,
                        customerId: (_b = customer === null || customer === void 0 ? void 0 : customer.id) !== null && _b !== void 0 ? _b : null,
                    }];
        }
    });
}); };
exports.getMemberDetails = getMemberDetails;
var searchForProduct = function (searchString, inventoryChannelId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, ctapi_1.productProjectionSearchByText)(searchString)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, (0, utils_1.mapProducts)(response.results, inventoryChannelId)];
        }
    });
}); };
exports.searchForProduct = searchForProduct;
var createCart = function (email, item, customerId) { return __awaiter(void 0, void 0, void 0, function () {
    var body, cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    currency: "GBP",
                    country: "GB",
                    taxMode: "Platform",
                    taxCalculationMode: "UnitPriceLevel",
                    taxRoundingMode: "HalfUp",
                    customerEmail: email,
                    customerId: customerId !== null && customerId !== void 0 ? customerId : undefined,
                    customerGroup: customerId
                        ? undefined
                        : {
                            typeId: "customer-group",
                            key: DEFAULT_CUSTOMER_GROUP,
                        },
                    lineItems: [(0, utils_1.toLineItemDraft)(item)],
                    itemShippingAddresses: [
                        {
                            key: "store",
                            country: "GB",
                        },
                        {
                            key: "customer",
                            country: "GB",
                        },
                    ],
                    shippingAddress: {
                        country: "GB",
                    },
                    origin: "Merchant",
                    deleteDaysAfterLastModification: 1,
                };
                return [4 /*yield*/, (0, ctapi_1.createCartInStore)(body)];
            case 1:
                cart = _a.sent();
                return [2 /*return*/, (0, utils_1.mapCtCartToCartType)(cart)];
        }
    });
}); };
exports.createCart = createCart;
var addItem = function (cartRef, lineItem) { return __awaiter(void 0, void 0, void 0, function () {
    var body, cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    version: cartRef.version,
                    actions: [
                        {
                            action: "addLineItem",
                            sku: lineItem.sku,
                            distributionChannel: { key: "online-uk", typeId: "channel" },
                            supplyChannel: { key: "bpuk", typeId: "channel" },
                            quantity: lineItem.deliverQuantity + lineItem.dispatchQuantity,
                            shippingDetails: {
                                targets: [
                                    { addressKey: "store", quantity: lineItem.dispatchQuantity },
                                    { addressKey: "customer", quantity: lineItem.deliverQuantity },
                                ],
                            },
                        },
                    ],
                };
                return [4 /*yield*/, (0, ctapi_1.updateCart)(cartRef.id, body)];
            case 1:
                cart = _a.sent();
                return [2 /*return*/, (0, utils_1.mapCtCartToCartType)(cart)];
        }
    });
}); };
exports.addItem = addItem;
var deleteItem = function (cartRef, lineItemId) { return __awaiter(void 0, void 0, void 0, function () {
    var body, cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    version: cartRef.version,
                    actions: [
                        {
                            action: "removeLineItem",
                            lineItemId: lineItemId,
                        },
                    ],
                };
                return [4 /*yield*/, (0, ctapi_1.updateCart)(cartRef.id, body)];
            case 1:
                cart = _a.sent();
                return [2 /*return*/, (0, utils_1.mapCtCartToCartType)(cart)];
        }
    });
}); };
exports.deleteItem = deleteItem;
var editQuantity = function (cartRef, lineItemId, dispatchQuantity, deliveryQuantity) { return __awaiter(void 0, void 0, void 0, function () {
    var body, cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    version: cartRef.version,
                    actions: [
                        {
                            action: "changeLineItemQuantity",
                            lineItemId: lineItemId,
                            quantity: dispatchQuantity + deliveryQuantity,
                        },
                        {
                            action: "setLineItemShippingDetails",
                            lineItemId: lineItemId,
                            shippingDetails: {
                                targets: [
                                    {
                                        addressKey: "store",
                                        quantity: dispatchQuantity,
                                    },
                                    {
                                        addressKey: "customer",
                                        quantity: deliveryQuantity,
                                    },
                                ],
                            },
                        },
                    ],
                };
                return [4 /*yield*/, (0, ctapi_1.updateCart)(cartRef.id, body)];
            case 1:
                cart = _a.sent();
                return [2 /*return*/, (0, utils_1.mapCtCartToCartType)(cart)];
        }
    });
}); };
exports.editQuantity = editQuantity;
var createOrder = function (cartRef, deliveryType, paymentRef, deliveryAddress) { return __awaiter(void 0, void 0, void 0, function () {
    var shipmentState, orderState, hasItemsToDeliver, cart, shippingMethodAction, setShippingAddressAction, shippingActions, paymentKey, paymentDraft, payment, addPaymentBody, orderFromCartDraft, order;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                shipmentState = utils_1.deliveryTypeToShipmentState[deliveryType];
                orderState = utils_1.deliveryTypeToOrderState[deliveryType];
                hasItemsToDeliver = deliveryType !== "in-store";
                return [4 /*yield*/, (0, ctapi_1.getCartById)(cartRef.id)];
            case 1:
                cart = _a.sent();
                shippingMethodAction = {
                    action: "setShippingMethod",
                    shippingMethod: {
                        typeId: "shipping-method",
                        key: "STDDEL",
                    },
                };
                setShippingAddressAction = hasItemsToDeliver
                    ? {
                        action: "setShippingAddress",
                        address: __assign(__assign({}, deliveryAddress), { key: "customer" }),
                    }
                    : {
                        action: "setShippingAddress",
                        address: helpers_1.popupAddress,
                    };
                shippingActions = hasItemsToDeliver
                    ? [shippingMethodAction, setShippingAddressAction]
                    : [setShippingAddressAction];
                return [4 /*yield*/, (0, ctapi_1.updateCart)(cart.id, {
                        version: cart.version,
                        actions: shippingActions,
                    })];
            case 2:
                cart = _a.sent();
                paymentKey = "".concat(cart.key, "-").concat(cart.version, "-").concat(paymentRef);
                paymentDraft = {
                    key: paymentKey,
                    amountPlanned: cart.totalPrice,
                    paymentMethodInfo: {
                        paymentInterface: "wp-terminal",
                        method: "Card",
                        name: {
                            en: "Physical terminal",
                        },
                    },
                    paymentStatus: {
                        interfaceText: paymentRef,
                    },
                    transactions: [
                        {
                            timestamp: new Date(Date.now()).toISOString(),
                            type: "Authorization",
                            state: "Success",
                            amount: cart.totalPrice,
                            interactionId: paymentKey,
                        },
                    ],
                };
                return [4 /*yield*/, (0, ctapi_1.createPayment)(paymentDraft)];
            case 3:
                payment = _a.sent();
                addPaymentBody = {
                    version: cart.version,
                    actions: [
                        {
                            action: "addPayment",
                            payment: {
                                typeId: "payment",
                                id: payment.id,
                            },
                        },
                    ],
                };
                return [4 /*yield*/, (0, ctapi_1.updateCart)(cart.id, addPaymentBody)];
            case 4:
                cart = _a.sent();
                orderFromCartDraft = {
                    cart: {
                        typeId: "cart",
                        id: cart.id,
                    },
                    orderNumber: cart.key,
                    version: cart.version,
                    paymentState: "Paid",
                    orderState: orderState,
                    shipmentState: shipmentState,
                };
                return [4 /*yield*/, (0, ctapi_1.createOrderFromCartDraft)(orderFromCartDraft)];
            case 5:
                order = _a.sent();
                // returning whole order for testability
                return [2 /*return*/, order];
        }
    });
}); };
exports.createOrder = createOrder;
var updateInventoryEntries = function (cartId, inventoryChannelId) { return __awaiter(void 0, void 0, void 0, function () {
    var cart, inventoryIds, inventoryEntries;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, ctapi_1.getCartById)(cartId)];
            case 1:
                cart = _a.sent();
                inventoryIds = cart.lineItems.map(function (li) {
                    var _a;
                    return ((_a = li.variant.availability.channels) === null || _a === void 0 ? void 0 : _a[inventoryChannelId]).id;
                });
                return [4 /*yield*/, (0, ctapi_1.queryInventoryEntries)(inventoryIds)];
            case 2:
                inventoryEntries = (_a.sent()).results;
                return [2 /*return*/, Promise.all(cart.lineItems.map(function (lineItem) { return __awaiter(void 0, void 0, void 0, function () {
                        var inventory, quantity, inventoryUpdate;
                        return __generator(this, function (_a) {
                            inventory = inventoryEntries.find(function (ie) {
                                var _a;
                                return ie.id ===
                                    ((_a = lineItem.variant.availability.channels) === null || _a === void 0 ? void 0 : _a[inventoryChannelId]).id;
                            });
                            quantity = lineItem.quantity;
                            inventoryUpdate = {
                                version: inventory.version,
                                actions: [
                                    {
                                        action: "removeQuantity",
                                        quantity: quantity,
                                    },
                                ],
                            };
                            return [2 /*return*/, (0, ctapi_1.updateInventoryEntry)(inventory.id, inventoryUpdate)];
                        });
                    }); }))];
        }
    });
}); };
exports.updateInventoryEntries = updateInventoryEntries;
