"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.List = void 0;
var react_1 = __importStar(require("react"));
var ui_kit_1 = require("@commercetools-frontend/ui-kit");
var react_router_dom_1 = require("react-router-dom");
var hooks_1 = require("@src/hooks");
var components_1 = require("@src/components");
var columns = [
    { key: "id", label: "Code" },
    { key: "name", label: "Name" },
    { key: "spendLimit", label: "Spend Limit" },
    { key: "duration", label: "Duration" },
    { key: "visibility", label: "Visibility" },
    { key: "active", label: "Active" },
];
var AddButton = function () {
    var history = (0, react_router_dom_1.useHistory)();
    return (react_1.default.createElement(ui_kit_1.SecondaryButton, { label: "Create a membership", iconLeft: react_1.default.createElement(ui_kit_1.ViewGridPlusIcon, null), onClick: function () { return history.push("details"); } }));
};
var List = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var _a = (0, react_1.useState)([]), rows = _a[0], setRows = _a[1];
    var _b = (0, hooks_1.useMemberships)(), search = _b.search, state = _b.state;
    var _c = (0, hooks_1.usePagination)(), pagination = _c[0], updatePagination = _c[1];
    var onRowClick = (0, react_1.useCallback)(function (obj) { return history.push("details/".concat(obj.ctId)); }, []);
    (0, react_1.useEffect)(function () { return search(__assign({}, pagination)); }, [pagination.offset]);
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if ((_b = (_a = state.search.data) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.length) {
            var _c = state.search.data, results = _c.results, offset = _c.offset, total = _c.total;
            var data = results.map(function (_a) {
                var id = _a.id, value = _a.value;
                return ({
                    id: "".concat(value.code),
                    ctId: id,
                    name: value.name,
                    active: value.active ? "yes" : "no",
                    duration: "".concat(value.duration, " months"),
                    spendLimit: "".concat(value.spendLimit, " / ").concat(parseInt(value.spendLimitRotation, 10) === 1
                        ? "month"
                        : "".concat(value.spendLimitRotation, " months")),
                    visibility: value.visibility,
                });
            });
            setRows(data);
            updatePagination({ offset: offset, total: total });
        }
    }, [state.search.data]);
    return (react_1.default.createElement(components_1.Page, { permissions: ["ViewProducts"], title: "Memberships Available", nav: react_1.default.createElement(AddButton, null), loading: state.search.loading, error: state.search.error }, rows.length ? (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.Pagination, __assign({}, pagination, { updatePagination: updatePagination })),
        react_1.default.createElement(ui_kit_1.DataTableManager, { columns: columns },
            react_1.default.createElement(ui_kit_1.DataTable, { rows: rows, onRowClick: onRowClick })))) : (react_1.default.createElement("div", null,
        "There are no membership plans at the moment.",
        " ",
        react_1.default.createElement(react_router_dom_1.Link, { to: "./details" }, "Create one!")))));
};
exports.List = List;
