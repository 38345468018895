"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapCtCartToCartType = void 0;
var mapLineItems_1 = require("./mapLineItems");
var calculateTax = function (cart) {
    return cart.taxedPrice.taxPortions.reduce(function (prev, curr) {
        return prev + curr.amount.centAmount;
    }, 0);
};
var mapCtCartToCartType = function (cart) {
    return {
        id: cart.id,
        version: cart.version,
        items: (0, mapLineItems_1.mapLineItemTypes)(cart.lineItems),
        total: cart.totalPrice.centAmount,
        tax: calculateTax(cart),
    };
};
exports.mapCtCartToCartType = mapCtCartToCartType;
