"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRef = void 0;
var react_1 = __importStar(require("react"));
var hooks_1 = require("../../hooks");
require("./PaymentRef.module.css");
var PaymentRef = function () {
    var setPaymentRef = (0, hooks_1.usePaymentRef)().setPaymentRef;
    var cartItems = (0, hooks_1.useCartItems)().cartItems;
    var error = (0, hooks_1.useError)().error;
    var _a = (0, react_1.useState)(""), enteredRef = _a[0], setEnteredRef = _a[1];
    var paymentRefRegEx = new RegExp(/^[A-Za-z0-9]{6}$/);
    (0, react_1.useEffect)(function () {
        if (!cartItems.length && enteredRef) {
            setEnteredRef("");
        }
        if (paymentRefRegEx.test(enteredRef)) {
            setPaymentRef(enteredRef);
        }
        else {
            setPaymentRef(null);
        }
    }, [enteredRef, cartItems]);
    if (!cartItems.length)
        return null;
    return (react_1.default.createElement("div", { className: "payment-ref-container" },
        react_1.default.createElement("p", { className: "payment-ref-heading" }, "Payment auth code"),
        react_1.default.createElement("input", { value: enteredRef !== null && enteredRef !== void 0 ? enteredRef : "", onChange: function (event) { return setEnteredRef(event.target.value); }, inputMode: "numeric", className: "payment-ref-input", "data-testid": "payment-ref-input", disabled: !!error })));
};
exports.PaymentRef = PaymentRef;
