"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideCTBars = void 0;
// Loads CSS to hide the CT nav and side bars. Only needed for pop up store
var hideCTBars = function () {
    if (window.location.pathname.includes("popup-store")) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var css = document.createElement("style");
        var styles = "[role='main'] {\n      position: absolute !important;\n      top: 0;\n      left: 0;\n      width: 100vw;\n      min-height: 100vh;\n      z-index: 99999;\n      background: white;\n    }";
        css.appendChild(document.createTextNode(styles));
        document.getElementsByTagName("head")[0].appendChild(css);
    }
};
exports.hideCTBars = hideCTBars;
