"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoqateControl = void 0;
var react_1 = require("react");
var useLoqateControl = function (deliveryAddress) {
    var _a = deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.streetName.split("|").map(function (line) { return line.trim(); }).filter(Boolean), lineOne = _a[0], lineTwo = _a[1];
    var _b = (0, react_1.useState)({
        postalCode: deliveryAddress.postalCode,
        lineOne: lineOne,
        lineTwo: lineTwo,
        city: deliveryAddress.city,
        country: "United Kingdom",
    }), deliveryAddressState = _b[0], setDeliveryAddressState = _b[1];
    var onPopulate = (0, react_1.useCallback)(function (address) {
        // Map Loqate address format to our format.
        var newAddress = {
            postalCode: address.PostalCode,
            lineOne: address.Line1,
            lineTwo: address.Line2,
            city: address.City,
            country: address.CountryName,
        };
        setDeliveryAddressState(newAddress);
    }, []);
    (0, react_1.useEffect)(function () {
        if (!window.pca) {
            console.error("Window.pca is not defined");
            return;
        }
        var pca = window.pca;
        /* eslint-disable no-bitwise */
        var fields = [
            {
                element: "PostalCode",
                field: "PostalCode",
                mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
            },
            {
                element: "Line1",
                field: "Line1",
                mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
            },
            {
                element: "Line2",
                field: "Line2",
                mode: pca.fieldMode.POPULATE,
            },
            {
                element: "City",
                field: "City",
                mode: pca.fieldMode.POPULATE,
            },
            {
                element: "CountryName",
                field: "CountryName",
                mode: pca.fieldMode.COUNTRY,
            },
        ];
        var options = {
            key: "GA79-CF85-PA56-JR23",
            setCountryByIP: false,
            countries: {
                codesList: "GBR",
            },
        };
        var control = new pca.Address(fields, options);
        control.load();
        control.listen("populate", onPopulate);
        return function () { return control.destroy(); };
    }, [deliveryAddressState, onPopulate, window.pca]);
    return { deliveryAddressState: deliveryAddressState, setDeliveryAddressState: setDeliveryAddressState };
};
exports.useLoqateControl = useLoqateControl;
