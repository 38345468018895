"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressForm = void 0;
var react_1 = __importStar(require("react"));
var hooks_1 = require("../../hooks");
var AddressFormHooks_1 = require("./AddressFormHooks");
var helpers_1 = require("./helpers");
var AddressForm = function () {
    var _a, _b, _c, _d, _e;
    var _f = (0, hooks_1.useDeliveryAddress)(), deliveryAddress = _f.deliveryAddress, setDeliveryAddress = _f.setDeliveryAddress;
    var error = (0, hooks_1.useError)().error;
    var _g = (0, AddressFormHooks_1.useLoqateControl)(deliveryAddress), deliveryAddressState = _g.deliveryAddressState, setDeliveryAddressState = _g.setDeliveryAddressState;
    var addressUpdateHandler = function (e) {
        var _a;
        setDeliveryAddressState(__assign(__assign({}, deliveryAddressState), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    var nameUpdateHandler = function (e) {
        var _a;
        setDeliveryAddress(__assign(__assign({}, deliveryAddress), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    (0, react_1.useEffect)(function () {
        var ctAddress = (0, helpers_1.loqateAddressToCTAddress)(deliveryAddressState);
        var newPostalCode = ctAddress.postalCode, newStreetName = ctAddress.streetName, newCity = ctAddress.city;
        // update global state with the new delivery address
        setDeliveryAddress(__assign(__assign({}, deliveryAddress), { postalCode: newPostalCode, streetName: newStreetName, city: newCity, country: "GB" }));
    }, [deliveryAddressState]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("p", { className: "heading", "data-testid": "delivery-details" }, "Delivery Details"),
        react_1.default.createElement("div", { className: "addressForm" },
            react_1.default.createElement("div", { className: "customerName" },
                react_1.default.createElement("div", { className: "inputBlock", style: { paddingRight: "24px" } },
                    react_1.default.createElement("p", { style: { paddingTop: "8px" } }, "First name"),
                    react_1.default.createElement("input", { type: "text", name: "firstName", defaultValue: (_a = deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.firstName) !== null && _a !== void 0 ? _a : "", onChange: nameUpdateHandler, disabled: !!error })),
                react_1.default.createElement("div", { className: "inputBlock" },
                    react_1.default.createElement("p", { style: { paddingTop: "8px" } }, "Last name"),
                    react_1.default.createElement("input", { type: "text", name: "lastName", defaultValue: deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.lastName, onChange: nameUpdateHandler, disabled: !!error }))),
            react_1.default.createElement("form", null,
                react_1.default.createElement("div", { className: "inputBlock" },
                    react_1.default.createElement("p", null, "Postcode"),
                    react_1.default.createElement("input", { id: "PostalCode", type: "text", name: "postalCode", className: "loqateInput", "data-testid": "postal-code", defaultValue: (_b = deliveryAddressState === null || deliveryAddressState === void 0 ? void 0 : deliveryAddressState.postalCode) !== null && _b !== void 0 ? _b : "", onChange: addressUpdateHandler, disabled: !!error })),
                react_1.default.createElement("div", { className: "inputBlock" },
                    react_1.default.createElement("p", null, "Address Line 1"),
                    react_1.default.createElement("input", { id: "Line1", type: "text", name: "lineOne", className: "", defaultValue: (_c = deliveryAddressState === null || deliveryAddressState === void 0 ? void 0 : deliveryAddressState.lineOne) !== null && _c !== void 0 ? _c : "", onChange: addressUpdateHandler, disabled: !!error })),
                react_1.default.createElement("div", { className: "inputBlock" },
                    react_1.default.createElement("p", null, "Address Line 2 (optional)"),
                    react_1.default.createElement("input", { id: "Line2", type: "text", name: "lineTwo", defaultValue: (_d = deliveryAddressState === null || deliveryAddressState === void 0 ? void 0 : deliveryAddressState.lineTwo) !== null && _d !== void 0 ? _d : "", className: "", onChange: addressUpdateHandler, disabled: !!error })),
                react_1.default.createElement("div", { className: "inputBlock" },
                    react_1.default.createElement("p", null, "Town / City"),
                    react_1.default.createElement("input", { id: "City", type: "text", name: "city", defaultValue: (_e = deliveryAddressState === null || deliveryAddressState === void 0 ? void 0 : deliveryAddressState.city) !== null && _e !== void 0 ? _e : "", className: "", onChange: addressUpdateHandler, disabled: !!error })),
                react_1.default.createElement("div", { className: "inputBlock" },
                    react_1.default.createElement("p", null, "Country"),
                    react_1.default.createElement("input", { id: "CountryName", type: "text", name: "country", defaultValue: "United Kingdom", readOnly: true, style: { backgroundColor: "#c4c4c4", color: "#000000" }, disabled: !!error }))))));
};
exports.AddressForm = AddressForm;
