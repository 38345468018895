"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreProvider = exports.StoreContext = exports.initialState = void 0;
var react_1 = __importStar(require("react"));
exports.initialState = {
    pageState: "idle",
    emailAddress: null,
    isMember: false,
    customerId: null,
    cartId: null,
    cartVersion: -1,
    cartItems: [],
    cartTotal: 0,
    cartTax: 0,
    deliveryAddress: null,
    paymentRef: null,
    error: null,
    orderNumber: null,
    inventoryChannelId: null,
    legalCheck: false,
};
exports.StoreContext = react_1.default.createContext({
    data: exports.initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setData: function (reducer) { return undefined; },
});
var StoreProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(exports.initialState), data = _b[0], setData = _b[1];
    var value = {
        data: data,
        setData: setData,
    };
    return (react_1.default.createElement(exports.StoreContext.Provider, { value: value }, children));
};
exports.StoreProvider = StoreProvider;
