"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetCart = void 0;
var _1 = require(".");
var useResetCart = function () {
    var setCartId = (0, _1.useCartId)().setCartId;
    var setCartVersion = (0, _1.useCartVersion)().setCartVersion;
    var setCartItems = (0, _1.useCartItems)().setCartItems;
    var setCartTotal = (0, _1.useCartTotal)().setCartTotal;
    var setDeliveryAddress = (0, _1.useDeliveryAddress)().setDeliveryAddress;
    var setPaymentRef = (0, _1.usePaymentRef)().setPaymentRef;
    return {
        resetCart: function () {
            setCartId(null);
            setCartVersion(-1);
            setCartItems([]);
            setCartTotal(0);
            setDeliveryAddress(null);
            setPaymentRef(null);
        },
    };
};
exports.useResetCart = useResetCart;
