"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
var react_1 = require("react");
var DEFAULT_LIMIT = 20;
var usePagination = function (_a) {
    var _b = _a === void 0 ? {
        limit: DEFAULT_LIMIT,
        offset: 0,
        total: 0,
    } : _a, limit = _b.limit, offset = _b.offset, total = _b.total;
    var updatePagination = function (update) {
        setPagination(function (prev) { return (__assign(__assign({}, prev), update)); });
    };
    var _c = (0, react_1.useState)({
        limit: limit,
        offset: offset,
        total: total,
    }), pagination = _c[0], setPagination = _c[1];
    return [pagination, updatePagination];
};
exports.usePagination = usePagination;
