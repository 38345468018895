"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAttributeByName = exports.convertLocalized = exports.notEmpty = void 0;
var notEmpty = function (value) {
    return value !== null && value !== undefined;
};
exports.notEmpty = notEmpty;
var convertLocalized = function (input, locale) {
    var localized = input === null || input === void 0 ? void 0 : input[locale];
    return !(0, exports.notEmpty)(localized) || localized === "MISSING_DATA" ? "" : localized;
};
exports.convertLocalized = convertLocalized;
function getAttributeByName(name, attrs) {
    var _a;
    return (_a = attrs === null || attrs === void 0 ? void 0 : attrs.find(function (el) { return el.name === name; })) === null || _a === void 0 ? void 0 : _a.value;
}
exports.getAttributeByName = getAttributeByName;
