"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateInventoryEntry = exports.queryInventoryEntries = exports.getUKInventoryChannel = exports.createOrderFromCartDraft = exports.createPayment = exports.deleteCart = exports.updateCart = exports.createCartInStore = exports.getCartById = exports.productProjectionSearchByText = exports.queryCustomersByEmail = void 0;
var utils_1 = require("../utils");
var projectKey = (_a = localStorage.getItem("activeProjectKey")) !== null && _a !== void 0 ? _a : "bp-preview";
var ctApiUrl = "https://mc-api.europe-west1.gcp.commercetools.com/proxy/ctp";
var get = function (_a) {
    var resource = _a.resource, resourceId = _a.resourceId, storeKey = _a.storeKey, options = _a.options;
    return __awaiter(void 0, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = (0, utils_1.urlBuilder)({
                        ctApiUrl: ctApiUrl,
                        projectKey: projectKey,
                        resource: resource,
                        resourceId: resourceId,
                        storeKey: storeKey,
                        options: options,
                    });
                    return [4 /*yield*/, fetch(url, {
                            method: "GET",
                            headers: {
                                Accept: "application/json",
                            },
                            credentials: "include",
                        })];
                case 1:
                    response = _b.sent();
                    if (!response.ok)
                        return [2 /*return*/, null];
                    return [2 /*return*/, response.json()];
            }
        });
    });
};
var post = function (_a) {
    var resource = _a.resource, storeKey = _a.storeKey, _b = _a.resourceId, resourceId = _b === void 0 ? "" : _b, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    url = (0, utils_1.urlBuilder)({
                        ctApiUrl: ctApiUrl,
                        projectKey: projectKey,
                        resource: resource,
                        resourceId: resourceId,
                        storeKey: storeKey,
                    });
                    return [4 /*yield*/, fetch(url, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            credentials: "include",
                            body: JSON.stringify(body),
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.ok)
                        return [2 /*return*/, null];
                    return [2 /*return*/, response.json()];
            }
        });
    });
};
var queryCustomersByEmail = function (emailAddress) { return __awaiter(void 0, void 0, void 0, function () {
    var urlParams, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                urlParams = new URLSearchParams({
                    where: "lowercaseEmail=\"".concat(emailAddress.toLowerCase(), "\""),
                    expand: "customerGroup",
                });
                return [4 /*yield*/, get({
                        resource: "customers",
                        storeKey: "uk-store",
                        options: {
                            queryArgs: urlParams,
                        },
                    })];
            case 1:
                response = _a.sent();
                if (!response) {
                    throw new Error("Could not check user details");
                }
                return [2 /*return*/, response];
        }
    });
}); };
exports.queryCustomersByEmail = queryCustomersByEmail;
var productProjectionSearchByText = function (text) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, get({
                    resource: "product-projections/search",
                    options: {
                        queryArgs: new URLSearchParams({
                            markMatchingVariants: "true",
                            limit: "20",
                            filter: "variants.attributes.channel.key:\"online-uk\"",
                            "text.en-GB": text,
                        }),
                    },
                })];
            case 1:
                response = _a.sent();
                if (!response) {
                    throw new Error("Could not search for products");
                }
                return [2 /*return*/, response];
        }
    });
}); };
exports.productProjectionSearchByText = productProjectionSearchByText;
var getCartById = function (cartId) { return __awaiter(void 0, void 0, void 0, function () {
    var cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, get({
                    resource: "carts",
                    resourceId: cartId,
                    storeKey: "uk-store",
                })];
            case 1:
                cart = _a.sent();
                if (!cart) {
                    throw new Error("Cannot get cart: ".concat(cartId));
                }
                return [2 /*return*/, cart];
        }
    });
}); };
exports.getCartById = getCartById;
var createCartInStore = function (cartDraft) { return __awaiter(void 0, void 0, void 0, function () {
    var cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, post({
                    resource: "carts",
                    storeKey: "uk-store",
                    body: cartDraft,
                })];
            case 1:
                cart = _a.sent();
                if (!cart) {
                    throw new Error("Could not create cart");
                }
                return [2 /*return*/, cart];
        }
    });
}); };
exports.createCartInStore = createCartInStore;
var updateCart = function (cartId, cartUpdate) { return __awaiter(void 0, void 0, void 0, function () {
    var cart;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, post({
                    resource: "carts",
                    resourceId: cartId,
                    storeKey: "uk-store",
                    body: cartUpdate,
                })];
            case 1:
                cart = _a.sent();
                if (!cart) {
                    throw new Error("Could not update cart: ".concat(cartId));
                }
                return [2 /*return*/, cart];
        }
    });
}); };
exports.updateCart = updateCart;
// do we need this? cart is deleted automatically after 1 day
var deleteCart = function (cartRef) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                url = (0, utils_1.urlBuilder)({
                    ctApiUrl: ctApiUrl,
                    projectKey: projectKey,
                    resource: "carts",
                    resourceId: cartRef.id,
                    options: {
                        queryArgs: new URLSearchParams({
                            version: cartRef.version.toString(),
                        }),
                    },
                });
                return [4 /*yield*/, fetch(url, {
                        method: "DELETE",
                        headers: {
                            Accept: "application/json",
                        },
                        credentials: "include",
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.ok];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.deleteCart = deleteCart;
var createPayment = function (paymentDraft) { return __awaiter(void 0, void 0, void 0, function () {
    var payment;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, post({
                    resource: "payments",
                    body: paymentDraft,
                })];
            case 1:
                payment = _a.sent();
                if (!payment) {
                    throw new Error("Could not create payment");
                }
                return [2 /*return*/, payment];
        }
    });
}); };
exports.createPayment = createPayment;
var createOrderFromCartDraft = function (orderFromCartDraft) { return __awaiter(void 0, void 0, void 0, function () {
    var order;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, post({
                    resource: "orders",
                    storeKey: "uk-store",
                    body: orderFromCartDraft,
                })];
            case 1:
                order = _a.sent();
                if (!order) {
                    throw new Error("Could not create order for cart: ".concat(orderFromCartDraft.cart.id));
                }
                return [2 /*return*/, order];
        }
    });
}); };
exports.createOrderFromCartDraft = createOrderFromCartDraft;
var getUKInventoryChannel = function () { return __awaiter(void 0, void 0, void 0, function () {
    var channels;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, get({
                    resource: "channels",
                    options: {
                        queryArgs: new URLSearchParams({
                            where: "key=\"bpuk\"",
                        }),
                    },
                })];
            case 1:
                channels = _a.sent();
                if (!(channels === null || channels === void 0 ? void 0 : channels.results[0])) {
                    throw new Error("Could not get UK inventory channel");
                }
                return [2 /*return*/, channels.results[0]];
        }
    });
}); };
exports.getUKInventoryChannel = getUKInventoryChannel;
var queryInventoryEntries = function (inventoryIds) { return __awaiter(void 0, void 0, void 0, function () {
    var joinedIds, where, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                joinedIds = inventoryIds.map(function (id) { return "\"".concat(id, "\""); }).join(",");
                where = "id in (".concat(joinedIds, ")");
                return [4 /*yield*/, get({
                        resource: "inventory",
                        options: {
                            queryArgs: new URLSearchParams({
                                where: where,
                                limit: inventoryIds.length.toString(),
                            }),
                        },
                    })];
            case 1:
                response = _a.sent();
                if (!response) {
                    throw new Error("Could not get inventory entries");
                }
                return [2 /*return*/, response];
        }
    });
}); };
exports.queryInventoryEntries = queryInventoryEntries;
var updateInventoryEntry = function (inventoryId, inventoryUpdate) { return __awaiter(void 0, void 0, void 0, function () {
    var inventoryEntry;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, post({
                    resource: "inventory",
                    resourceId: inventoryId,
                    body: inventoryUpdate,
                })];
            case 1:
                inventoryEntry = _a.sent();
                if (!inventoryEntry) {
                    throw new Error("Could not update inventory: ".concat(inventoryId));
                }
                return [2 /*return*/, inventoryEntry];
        }
    });
}); };
exports.updateInventoryEntry = updateInventoryEntry;
