"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalItems = void 0;
var getTotalItems = function (cartItems) {
    return cartItems.reduce(function (total, item) {
        var itemQty = item.deliverQuantity + item.dispatchQuantity;
        return total + itemQty;
    }, 0);
};
exports.getTotalItems = getTotalItems;
