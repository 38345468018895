"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShallowNavigate = void 0;
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var useShallowNavigate = function () {
    var _a = (0, react_router_dom_1.useParams)(), projectKey = _a.projectKey, app = _a.app;
    var history = (0, react_router_dom_1.useHistory)();
    var appBaseUrl = (0, react_1.useMemo)(function () { return "/".concat(projectKey, "/").concat(app); }, []);
    var navigateTo = (0, react_1.useCallback)(function (url) {
        document
            .querySelector("main")
            .scrollTo({ top: 0, left: 0, behavior: "smooth" });
        history.push(url);
    }, []);
    return { appBaseUrl: appBaseUrl, navigateTo: navigateTo };
};
exports.useShallowNavigate = useShallowNavigate;
