"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.List = void 0;
var react_1 = __importStar(require("react"));
var ui_kit_1 = require("@commercetools-frontend/ui-kit");
var components_1 = require("@src/components");
var hooks_1 = require("@src/hooks");
var utils_1 = require("./utils");
var List = function () {
    var _a = (0, react_1.useState)([]), rows = _a[0], setRows = _a[1];
    var _b = (0, react_1.useState)([]), columns = _b[0], setColumns = _b[1];
    var _c = (0, react_1.useState)(""), keyword = _c[0], setKeyword = _c[1];
    var _d = (0, hooks_1.usePagination)(), pagination = _d[0], updatePagination = _d[1];
    var _e = (0, react_1.useState)([]), data = _e[0], setData = _e[1];
    var _f = (0, hooks_1.useCustomObjects)("ingredients-product"), search = _f.search, state = _f.state;
    var _g = (0, hooks_1.useShallowNavigate)(), appBaseUrl = _g.appBaseUrl, navigateTo = _g.navigateTo;
    (0, react_1.useEffect)(function () {
        search({ limit: 500 });
        setColumns([
            { key: "idWithBtn", label: "ID" },
            { key: "title", label: "Title" },
            { key: "description", label: "Description" },
        ]);
    }, []);
    (0, react_1.useEffect)(function () {
        var pageData = data.slice(pagination.offset, pagination.offset + pagination.limit);
        var tableRows = pageData.map(function (_a) {
            var _b, _c, _d, _e;
            var id = _a.id, key = _a.key, value = _a.value;
            var title = (_c = (_b = value.title["en-GB"]) !== null && _b !== void 0 ? _b : value.title["en-US"]) !== null && _c !== void 0 ? _c : "";
            var description = (_e = (_d = value.title["en-GB"]) !== null && _d !== void 0 ? _d : value.title["en-US"]) !== null && _e !== void 0 ? _e : "";
            return {
                idWithBtn: react_1.default.createElement(components_1.Button.Copy, { copyValue: id }, id),
                id: id,
                key: key,
                title: title,
                description: description,
            };
        });
        setRows(tableRows);
    }, [data, pagination.offset, pagination.limit]);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c;
        var filtered = (0, utils_1.filterResults)(keyword, (_c = (_b = (_a = state.search.data) === null || _a === void 0 ? void 0 : _a.customObjects) === null || _b === void 0 ? void 0 : _b.results) !== null && _c !== void 0 ? _c : []);
        setData(filtered);
        updatePagination({ offset: 0, total: filtered.length });
    }, [state.search.data, keyword]);
    return (react_1.default.createElement(components_1.Page, { title: "Key Ingredients", permissions: ["ViewProducts", "ManageProducts"], loading: state.search.loading ? "Loading ingredients list..." : false, nav: react_1.default.createElement(components_1.Button.Add, { label: "Add an ingredient", onClick: function () { return navigateTo("".concat(appBaseUrl, "/new")); } }) },
        react_1.default.createElement(components_1.SearchBar, { keyword: keyword, onChange: function (w) { return setKeyword(w); } }),
        rows.length ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(components_1.Pagination, __assign({}, pagination, { updatePagination: updatePagination })),
            react_1.default.createElement(ui_kit_1.DataTableManager, { columns: columns },
                react_1.default.createElement(ui_kit_1.DataTable, { rows: rows, disableSelfContainment: true, onRowClick: function (_a) {
                        var id = _a.id;
                        return navigateTo("".concat(appBaseUrl, "/").concat(id));
                    } })))) : (react_1.default.createElement(ui_kit_1.Spacings.Inline, null,
            "There are no ingredients",
            keyword ? " containing \"".concat(keyword, "\"") : "",
            "."))));
};
exports.List = List;
