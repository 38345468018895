"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlBuilder = void 0;
var urlBuilder = function (_a) {
    var ctApiUrl = _a.ctApiUrl, projectKey = _a.projectKey, resource = _a.resource, resourceId = _a.resourceId, storeKey = _a.storeKey, options = _a.options;
    var url = "".concat(ctApiUrl, "/").concat(projectKey);
    if (storeKey) {
        url = url.concat("/", "in-store", "/", "key=", storeKey);
    }
    if (resource) {
        url = url.concat("/", resource);
    }
    if (resourceId) {
        url = url.concat("/", resourceId);
    }
    if (options === null || options === void 0 ? void 0 : options.queryArgs) {
        url = url.concat("?", "".concat(options.queryArgs));
    }
    return url;
};
exports.urlBuilder = urlBuilder;
