"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapProducts = void 0;
var utils_1 = require("@src/utils");
var productIsAvailableInChannel = function (projVariant) {
    var _a, _b, _c, _d;
    if (!projVariant) {
        return true;
    }
    var availabilityFlags = (_a = projVariant.attributes) === null || _a === void 0 ? void 0 : _a.find(function (attr) { return attr.name === "availabilityFlags"; });
    if (!availabilityFlags)
        return true;
    var inStockFlagAttribute = availabilityFlags === null || availabilityFlags === void 0 ? void 0 : availabilityFlags.value.find(function (availabilityFlag) {
        return availabilityFlag.some(function (attr) { var _a; return ((_a = attr === null || attr === void 0 ? void 0 : attr.value) === null || _a === void 0 ? void 0 : _a.key) === "emailBackInStock"; });
    });
    if (!inStockFlagAttribute)
        return true;
    var inStockFlags = (_b = inStockFlagAttribute.find(function (attr) { return (attr === null || attr === void 0 ? void 0 : attr.name) === "channelFlags"; })) === null || _b === void 0 ? void 0 : _b.value;
    if (!inStockFlags)
        return true;
    var storeSpecificInStockFlag = inStockFlags.find(function (flag) {
        // todo: make channel key dynamic
        return flag.some(function (attr) { var _a; return ((_a = attr.value) === null || _a === void 0 ? void 0 : _a.key) === "online-uk"; });
    });
    if (!storeSpecificInStockFlag)
        return true;
    // Assume the product is available unless we find a flag that says otherwise
    return ((_d = (_c = storeSpecificInStockFlag.find(function (attr) { return attr.name === "isAvailable"; })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : true);
};
var mapProducts = function (products, inventoryChannelId) {
    return products.flatMap(function (product) {
        var allVariants = __spreadArray([product.masterVariant], product.variants, true);
        return allVariants.map(function (variant) {
            var _a, _b, _c, _d, _e, _f, _g;
            var franchise = (_a = (0, utils_1.getAttributeByName)("productFranchise", variant.attributes)) === null || _a === void 0 ? void 0 : _a[0];
            var franchiseName = (0, utils_1.convertLocalized)((0, utils_1.getAttributeByName)("title", franchise), "en-GB");
            var variantTitle = (0, utils_1.convertLocalized)((0, utils_1.getAttributeByName)("title", variant.attributes), "en-GB");
            var productName = (0, utils_1.convertLocalized)(product.name, "en-GB");
            var isOnStock = productIsAvailableInChannel(variant) &&
                ((_d = (_c = (_b = variant.availability) === null || _b === void 0 ? void 0 : _b.channels) === null || _c === void 0 ? void 0 : _c[inventoryChannelId]) === null || _d === void 0 ? void 0 : _d.isOnStock);
            var hasLowStock = ((_g = (_f = (_e = variant.availability) === null || _e === void 0 ? void 0 : _e.channels) === null || _f === void 0 ? void 0 : _f[inventoryChannelId]) === null || _g === void 0 ? void 0 : _g.availableQuantity) < 20;
            var name = [franchiseName, productName, variantTitle].join(" ");
            return {
                sku: variant.sku,
                name: name,
                isOnStock: isOnStock,
                hasLowStock: hasLowStock,
            };
        });
    });
};
exports.mapProducts = mapProducts;
