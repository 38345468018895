"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = void 0;
var react_1 = __importStar(require("react"));
var ui_kit_1 = require("@commercetools-frontend/ui-kit");
var Pagination = function (_a) {
    var offset = _a.offset, limit = _a.limit, total = _a.total, updatePagination = _a.updatePagination;
    var setPage = (0, react_1.useCallback)(function (page) {
        return updatePagination({ offset: page * limit - limit });
    }, [offset, limit]);
    var setPerPage = (0, react_1.useCallback)(function (perPage) {
        updatePagination({ limit: perPage, offset: 0 });
    }, []);
    var currentPage = (0, react_1.useMemo)(function () { return Math.ceil(offset / limit) + 1; }, [offset, limit]);
    var numPages = (0, react_1.useMemo)(function () { return Math.ceil(total / limit); }, [total, limit]);
    return (react_1.default.createElement(ui_kit_1.Spacings.Inline, { justifyContent: "space-between" },
        react_1.default.createElement("div", { style: { flex: 1 } },
            react_1.default.createElement(ui_kit_1.PageSizeSelector, { perPage: limit, pageItems: total, onPerPageChange: setPerPage })),
        react_1.default.createElement(ui_kit_1.PageNavigator, { totalPages: numPages, page: currentPage, onPageChange: setPage })));
};
exports.Pagination = Pagination;
