"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapLineItemTypes = void 0;
var utils_1 = require("@src/utils");
var mapLineItemTypes = function (items) {
    return items.map(function (item) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var franchise = (_a = (0, utils_1.getAttributeByName)("productFranchise", item.variant.attributes)) === null || _a === void 0 ? void 0 : _a[0];
        var franchiseName = (0, utils_1.convertLocalized)((0, utils_1.getAttributeByName)("title", franchise), "en-GB");
        var variantTitle = (0, utils_1.convertLocalized)((0, utils_1.getAttributeByName)("title", item.variant.attributes), "en-GB");
        var productName = (0, utils_1.convertLocalized)(item.name, "en-GB");
        var name = [productName, variantTitle].join(" ");
        return {
            id: item.id,
            sku: item.variant.sku,
            franchise: franchiseName,
            name: name,
            dispatchQuantity: (_e = (_d = (_c = (_b = item.shippingDetails) === null || _b === void 0 ? void 0 : _b.targets) === null || _c === void 0 ? void 0 : _c.find(function (target) { return target.addressKey === "store"; })) === null || _d === void 0 ? void 0 : _d.quantity) !== null && _e !== void 0 ? _e : 0,
            deliverQuantity: (_j = (_h = (_g = (_f = item.shippingDetails) === null || _f === void 0 ? void 0 : _f.targets) === null || _g === void 0 ? void 0 : _g.find(function (target) { return target.addressKey === "customer"; })) === null || _h === void 0 ? void 0 : _h.quantity) !== null && _j !== void 0 ? _j : 0,
            price: item.price.value.centAmount,
        };
    });
};
exports.mapLineItemTypes = mapLineItemTypes;
